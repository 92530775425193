.inner-banner {


	&.fewos-tonning {
		background-image: url(RESOURCE/img/fewos-tonning.jpg);
	}

	/*&.fewos-tonning2 {
		background-image: url(RESOURCE/img/fewos-tonning2.jpg);
	}*/

	&.hafen-tonning {
		background-image: url(RESOURCE/img/hafen-tonning.jpg);
	}

	/*&.hafen-tonning2 {
		background-image: url(RESOURCE/img/hafen-tonning2.jpg);
	}*/

	&.marktplatz-tonning {
		background-image: url(RESOURCE/img/marktplatz-tonning.jpg);
	}

	&.meeresfreibad-tonning {
		background-image: url(RESOURCE/img/meeresfreibad-tonning.jpg);
	}

	&.packhaus-tonning {
		background-image: url(RESOURCE/img/packhaus-tonning.jpg);
	}

	&.seehundstouren {
		background-image: url(RESOURCE/img/seehundstouren.jpg);
	}

	/*&.seehundstouren2 {
		background-image: url(RESOURCE/img/seehundstouren2.jpg);
	}*/
}