.inner-banner {

	&.eiderstedt_banner {
		background-image: url(RESOURCE/img/eiderstedt_banner.jpg);
	}

	&.badestellen {
		background-image: url(RESOURCE/img/badestellen.jpg);
	}

	&.friedrichstadt {
		background-image: url(RESOURCE/img/friedrichstadt.jpg);
	}

	&.westerhever-leuchtturm {
		background-image: url(RESOURCE/img/westerhever-leuchtturm.jpg);
	}
}