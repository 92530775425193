.anfahrt-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-anfahrt.jpg);
		background-position: left center;
	}

	ul {
		list-style-type: disc;
		margin-left: 20px;

		ul {
			list-style-type: circle;
			margin-block-start: 0px;
			margin-block-end: 0px;
		}
	}

	.contact-map {
		height: 350px;
	}
}