.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.heading1 {
		padding-left: 0;
	}

	.inner-banner {
		&.contact {
			background-image: url(RESOURCE/img/banner-contact2.webp);
			background-position: center top;
		}
	}
}