.welcome-img-bl {
	height: 400px;
	overflow: hidden;
	margin-bottom: 30px;

	img {
		width: 100%;
		height: 100%;
		object-position: top;
		object-fit: cover;
	}
}