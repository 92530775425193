.busum-v1 {
	margin-top: var(--page-margin-top);

	.img-center {
		object-position: center;
	}

	.inner-banner {
		background-image: url(RESOURCE/img/busum-banner.jpg);

		&.famili {
			background-image: url(RESOURCE/img/familienurlaub-nordsee-buesum.jpg);
			background-position: center bottom !important;
		}

		&.fewos-busum {
			background-image: url(RESOURCE/img/fewos-buesum.jpg);
		}

		&.stpeter {
			background-image: url(RESOURCE/img/familienurlaub-nordsee-buesum.jpg);
			background-position: center bottom !important;
		}

		&.helgoland {
			background-image: url(RESOURCE/img/helgoland-buesum.jpg);
		}

		&.kilmazonen {
			background-image: url(RESOURCE/img/klimazonen-stpeterording.jpg);
		}

		&.leuchttrume {
			background-image: url(RESOURCE/img/leuchtturm-spo.jpg);
		}

		&.leuchttrume {
			background-image: url(RESOURCE/img/leuchtturm-spo.jpg);
		}

		&.promenade {
			background-image: url(RESOURCE/img/promenade-buesum.jpg);
		}

		&.schiffahrt {
			background-image: url(RESOURCE/img/schifffahrt-buesum.jpg);
		}

		&.strand {
			background-image: url(RESOURCE/img/strandurlaub-stpeterording.jpg);
		}

		&.weltnaturebe {
			background-image: url(RESOURCE/img/weltnaturerbe-stpeterording.jpg);
		}

		&.weltnaturebe {
			background-image: url(RESOURCE/img/weltnaturerbe-stpeterording.jpg);
		}

		&.adventure {
			background-image: url(RESOURCE/img/adventure-golf.jpg);
		}

		&.familienlagune {
			background-image: url(RESOURCE/img/familienlagune.jpg);
		}

		&.geschichte {
			background-image: url(RESOURCE/img/geschichte-busum.jpg);
		}

		&.hafen {
			background-image: url(RESOURCE/img/hafen-busum.jpg);
		}

		&.hochdorfer {
			background-image: url(RESOURCE/img/garding.jpg);
		}


	}

	ul {
		list-style-type: disc;
		margin-left: 20px;

		ul {
			list-style-type: circle;
			margin-block-start: 0px;
			margin-block-end: 0px;
		}
	}
}